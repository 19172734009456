import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResultForbidden',
  render() {
    return (
      <svg
        viewBox="0 0 213 213"
        height="100%"
        width="100%"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinejoin: 'round',
          strokeMiterlimit: 2,
        }}
      >
        <g transform="matrix(1,0,0,1,-871.485,-445.62)">
          <g>
            <g transform="matrix(1,0,0,1,-75.2684,-87.3801)">
              <circle
                cx="1053.23"
                cy="639.477"
                r="106.477"
                style={{ fill: 'rgb(235, 238, 246)' }}
              />
            </g>
            <g transform="matrix(1,0,0,1,246.523,295.575)">
              <g transform="matrix(0.316667,0,0,0.316667,277.545,71.0298)">
                <g transform="matrix(0.989011,-0.571006,1.14201,0.659341,-335.171,81.4498)">
                  <rect
                    x="495.52"
                    y="1057.87"
                    width="105.078"
                    height="91"
                    style={{ fill: 'rgb(253, 243, 228)' }}
                  />
                </g>
                <g transform="matrix(0.164835,-0.0951676,1.14201,0.659341,116.224,-179.163)">
                  <rect
                    x="495.52"
                    y="1057.87"
                    width="105.078"
                    height="91"
                    style={{ fill: 'rgb(202, 174, 136)' }}
                  />
                </g>
                <g transform="matrix(0.978261,-0.564799,1.26804e-16,1.30435,-337.046,42.0327)">
                  <rect
                    x="1844.06"
                    y="1192.54"
                    width="106.232"
                    height="92"
                    style={{ fill: 'rgb(196, 173, 142)' }}
                  />
                </g>
                <g transform="matrix(0.267591,-0.154493,3.46856e-17,0.356787,992.686,475.823)">
                  <rect
                    x="1844.06"
                    y="1192.54"
                    width="106.232"
                    height="92"
                    style={{ fill: 'rgb(102, 102, 102)' }}
                  />
                </g>
                <g transform="matrix(1.28257,-0.740494,1.23317e-16,1.7101,1501.14,624.071)">
                  <g transform="matrix(1,0,0,1,-6,-6)">
                    <path
                      d="M2.25,10.5C2.25,10.5 1.5,10.5 1.5,9.75C1.5,9 2.25,6.75 6,6.75C9.75,6.75 10.5,9 10.5,9.75C10.5,10.5 9.75,10.5 9.75,10.5L2.25,10.5ZM6,6C7.234,6 8.25,4.984 8.25,3.75C8.25,2.516 7.234,1.5 6,1.5C4.766,1.5 3.75,2.516 3.75,3.75C3.75,4.984 4.766,6 6,6Z"
                      style={{ fill: 'white' }}
                    />
                  </g>
                </g>
                <g transform="matrix(0.725806,0.419045,1.75755e-17,1.01444,155.314,212.138)">
                  <rect
                    x="1663.92"
                    y="-407.511"
                    width="143.183"
                    height="118.292"
                    style={{ fill: 'rgb(240, 218, 183)' }}
                  />
                </g>
                <g transform="matrix(1.58977,-0.917857,1.15976e-16,2.2425,-1270.46,-614.379)">
                  <rect
                    x="1748.87"
                    y="1226.67"
                    width="10.895"
                    height="13.378"
                    style={{ fill: 'rgb(132, 97, 0)' }}
                  />
                </g>
              </g>
              <g transform="matrix(0.182997,0.105653,-0.494902,0.285732,814.161,66.3087)">
                <rect
                  x="657.012"
                  y="404.643"
                  width="198.586"
                  height="145.08"
                  style={{ fillOpacity: 0.1 }}
                />
              </g>
              <g transform="matrix(0.316667,0,0,0.316667,237.301,94.2647)">
                <g transform="matrix(0.989011,-0.571006,1.14201,0.659341,-335.171,81.4498)">
                  <rect
                    x="495.52"
                    y="1057.87"
                    width="105.078"
                    height="91"
                    style={{ fill: 'rgb(253, 243, 228)' }}
                  />
                </g>
                <g transform="matrix(0.164835,-0.0951676,1.14201,0.659341,116.224,-179.163)">
                  <rect
                    x="495.52"
                    y="1057.87"
                    width="105.078"
                    height="91"
                    style={{ fill: 'rgb(202, 174, 136)' }}
                  />
                </g>
                <g transform="matrix(0.978261,-0.564799,1.26804e-16,1.30435,-337.046,42.0327)">
                  <rect
                    x="1844.06"
                    y="1192.54"
                    width="106.232"
                    height="92"
                    style={{ fill: 'rgb(196, 173, 142)' }}
                  />
                </g>
                <g transform="matrix(0.267591,-0.154493,3.46856e-17,0.356787,992.686,475.823)">
                  <rect
                    x="1844.06"
                    y="1192.54"
                    width="106.232"
                    height="92"
                    style={{ fill: 'rgb(102, 102, 102)' }}
                  />
                </g>
                <g transform="matrix(1.28257,-0.740494,1.23317e-16,1.7101,1501.14,624.071)">
                  <g transform="matrix(1,0,0,1,-6,-6)">
                    <path
                      d="M2.25,10.5C2.25,10.5 1.5,10.5 1.5,9.75C1.5,9 2.25,6.75 6,6.75C9.75,6.75 10.5,9 10.5,9.75C10.5,10.5 9.75,10.5 9.75,10.5L2.25,10.5ZM6,6C7.234,6 8.25,4.984 8.25,3.75C8.25,2.516 7.234,1.5 6,1.5C4.766,1.5 3.75,2.516 3.75,3.75C3.75,4.984 4.766,6 6,6Z"
                      style={{ fill: 'white' }}
                    />
                  </g>
                </g>
                <g transform="matrix(0.725806,0.419045,1.75755e-17,1.01444,155.314,212.138)">
                  <rect
                    x="1663.92"
                    y="-407.511"
                    width="143.183"
                    height="118.292"
                    style={{ fill: 'rgb(240, 218, 183)' }}
                  />
                </g>
                <g transform="matrix(1.58977,-0.917857,1.15976e-16,2.2425,-1270.46,-614.379)">
                  <rect
                    x="1748.87"
                    y="1226.67"
                    width="10.895"
                    height="13.378"
                    style={{ fill: 'rgb(132, 97, 0)' }}
                  />
                </g>
              </g>
              <g transform="matrix(0.474953,0,0,0.474953,538.938,8.95289)">
                <g transform="matrix(0.180615,0.104278,-0.973879,0.562269,790.347,286.159)">
                  <rect
                    x="657.012"
                    y="404.643"
                    width="198.586"
                    height="145.08"
                    style={{ fillOpacity: 0.1 }}
                  />
                </g>
                <g transform="matrix(0.473356,0,0,0.473356,294.481,129.741)">
                  <g>
                    <g transform="matrix(0.1761,-0.101671,1.73518e-16,1.22207,442.564,7.31508)">
                      <rect
                        x="202.62"
                        y="575.419"
                        width="124.002"
                        height="259.402"
                        style={{ fill: 'rgb(235, 235, 235)' }}
                      />
                    </g>
                    <g transform="matrix(0.0922781,0.0532768,2.03964e-16,2.20569,405.236,-248.842)">
                      <rect
                        x="657.012"
                        y="404.643"
                        width="198.586"
                        height="145.08"
                        style={{ fill: 'rgb(34, 34, 34)' }}
                      />
                    </g>
                    <g transform="matrix(0.147541,-0.0851831,1.52371e-16,1.23446,454.294,-3.8127)">
                      <rect
                        x="202.62"
                        y="575.419"
                        width="124.002"
                        height="259.402"
                        style={{ fill: 'rgb(51, 51, 51)' }}
                      />
                    </g>
                    <g transform="matrix(0.0921286,0.0531905,-0.126106,0.0728076,474.688,603.724)">
                      <rect
                        x="657.012"
                        y="404.643"
                        width="198.586"
                        height="145.08"
                        style={{ fill: 'rgb(102, 102, 102)' }}
                      />
                    </g>
                  </g>
                </g>
                <g transform="matrix(0.473356,0,0,0.473356,192.621,188.549)">
                  <g>
                    <g transform="matrix(0.1761,-0.101671,1.73518e-16,1.22207,442.564,7.31508)">
                      <rect
                        x="202.62"
                        y="575.419"
                        width="124.002"
                        height="259.402"
                        style={{ fill: 'rgb(235, 235, 235)' }}
                      />
                    </g>
                    <g transform="matrix(0.0922781,0.0532768,2.03964e-16,2.20569,405.236,-248.842)">
                      <rect
                        x="657.012"
                        y="404.643"
                        width="198.586"
                        height="145.08"
                        style={{ fill: 'rgb(34, 34, 34)' }}
                      />
                    </g>
                    <g transform="matrix(0.147541,-0.0851831,1.52371e-16,1.23446,454.294,-3.8127)">
                      <rect
                        x="202.62"
                        y="575.419"
                        width="124.002"
                        height="259.402"
                        style={{ fill: 'rgb(51, 51, 51)' }}
                      />
                    </g>
                    <g transform="matrix(0.0921286,0.0531905,-0.126106,0.0728076,474.688,603.724)">
                      <rect
                        x="657.012"
                        y="404.643"
                        width="198.586"
                        height="145.08"
                        style={{ fill: 'rgb(102, 102, 102)' }}
                      />
                    </g>
                  </g>
                </g>
                <g transform="matrix(0.668111,0,0,0.668111,-123.979,-49.2109)">
                  <g transform="matrix(0.0349225,0.0201625,1.81598e-17,0.220789,974.758,729.412)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(235, 235, 235)' }}
                    />
                  </g>
                  <g transform="matrix(1.1164,-0.644557,0,0.220789,42.5091,1294.14)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(235, 235, 235)' }}
                    />
                  </g>
                  <g transform="matrix(0.0349225,0.0201625,-1.52814,0.882275,1593.11,461.746)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(102, 102, 102)' }}
                    />
                  </g>
                  <g transform="matrix(1.1164,-0.644557,0,0.220789,49.4442,1298.14)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(51, 51, 51)' }}
                    />
                  </g>
                  <g transform="matrix(0.0349225,0.0201625,1.81598e-17,0.220789,753.056,857.412)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(34, 34, 34)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,898.874,529.479)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,930.12,511.44)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,961.365,493.4)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,992.61,475.361)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,1023.86,457.321)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,1056.25,438.617)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,1085.74,421.589)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                </g>
                <g transform="matrix(0.668111,0,0,0.668111,-123.979,-91.97)">
                  <g transform="matrix(0.0349225,0.0201625,1.81598e-17,0.220789,974.758,729.412)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(235, 235, 235)' }}
                    />
                  </g>
                  <g transform="matrix(1.1164,-0.644557,0,0.220789,42.5091,1294.14)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(235, 235, 235)' }}
                    />
                  </g>
                  <g transform="matrix(0.0349225,0.0201625,-1.52814,0.882275,1593.11,461.746)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(102, 102, 102)' }}
                    />
                  </g>
                  <g transform="matrix(1.1164,-0.644557,0,0.220789,49.4442,1298.14)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(51, 51, 51)' }}
                    />
                  </g>
                  <g transform="matrix(0.0349225,0.0201625,1.81598e-17,0.220789,753.056,857.412)">
                    <rect
                      x="657.012"
                      y="404.643"
                      width="198.586"
                      height="145.08"
                      style={{ fill: 'rgb(34, 34, 34)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,898.874,529.479)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,930.12,511.44)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,961.365,493.4)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,992.61,475.361)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,1023.86,457.321)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,1056.25,438.617)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                  <g transform="matrix(0.142968,-0.0825428,-0.207261,0.478709,1085.74,421.589)">
                    <rect
                      x="831"
                      y="1023.79"
                      width="89.214"
                      height="89.214"
                      style={{ fill: 'rgb(255, 125, 0)' }}
                    />
                  </g>
                </g>
                <g transform="matrix(0.701585,5.16096e-35,-5.16096e-35,0.701585,-546.219,-21.3487)">
                  <g transform="matrix(0.558202,-0.322278,0,0.882275,1033.27,615.815)">
                    <path
                      d="M855.598,410.446C855.598,407.244 852.515,404.643 848.718,404.643L663.891,404.643C660.094,404.643 657.012,407.244 657.012,410.446L657.012,543.92C657.012,547.123 660.094,549.723 663.891,549.723L848.718,549.723C852.515,549.723 855.598,547.123 855.598,543.92L855.598,410.446Z"
                      style={{ fill: 'white' }}
                    />
                  </g>
                  <g transform="matrix(0.558202,-0.322278,0,0.882275,1035.25,616.977)">
                    <path
                      d="M855.598,410.446C855.598,407.244 852.515,404.643 848.718,404.643L663.891,404.643C660.094,404.643 657.012,407.244 657.012,410.446L657.012,543.92C657.012,547.123 660.094,549.723 663.891,549.723L848.718,549.723C852.515,549.723 855.598,547.123 855.598,543.92L855.598,410.446Z"
                      style={{ fill: 'white' }}
                    />
                  </g>
                  <g transform="matrix(1,0,0,1,418.673,507.243)">
                    <path
                      d="M1088.34,192.063C1089.79,191.209 1090.78,191.821 1090.78,191.821L1092.71,192.944C1092.71,192.944 1092.29,192.721 1091.7,192.763C1090.99,192.813 1090.34,193.215 1090.34,193.215C1090.34,193.215 1088.85,192.362 1088.34,192.063Z"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(1,0,0,1,235.984,-39.1315)">
                    <path
                      d="M1164.02,805.247C1164.05,802.517 1165.64,799.379 1167.67,798.118L1169.67,799.272C1167.58,800.648 1166.09,803.702 1166.02,806.402L1164.02,805.247Z"
                      style={{ fill: 'url(#_Linear1)' }}
                    />
                  </g>
                  <g transform="matrix(0.396683,0,0,0.396683,1000.22,516.921)">
                    <path
                      d="M1011.2,933.14C1009.31,932.075 1008.05,929.696 1007.83,926.324L1012.87,929.235C1012.87,929.235 1012.96,930.191 1013.04,930.698C1013.16,931.427 1013.42,932.344 1013.62,932.845C1013.79,933.255 1014.59,935.155 1016.22,936.046C1015.83,935.781 1011.19,933.139 1011.19,933.139L1011.2,933.14Z"
                      style={{ fill: 'rgb(238, 238, 238)' }}
                    />
                  </g>
                  <g transform="matrix(0.253614,-0.146424,4.87691e-17,0.338152,1209.98,830.02)">
                    <circle
                      cx="975.681"
                      cy="316.681"
                      r="113.681"
                      style={{ fill: 'rgb(245, 63, 63)' }}
                    />
                    <g transform="matrix(1.08844,0,0,0.61677,-99.9184,125.436)">
                      <path
                        d="M1062,297.556C1062,296.697 1061.61,296 1061.12,296L915.882,296C915.395,296 915,296.697 915,297.556L915,333.356C915,334.215 915.395,334.912 915.882,334.912L1061.12,334.912C1061.61,334.912 1062,334.215 1062,333.356L1062,297.556Z"
                        style={{ fill: 'white' }}
                      />
                    </g>
                  </g>
                  <g transform="matrix(5.57947,-3.22131,0.306277,0.176829,-6260.71,4938.32)">
                    <rect
                      x="1335.54"
                      y="694.688"
                      width="18.525"
                      height="6.511"
                      style={{ fill: 'rgb(248, 248, 248)' }}
                    />
                  </g>
                  <g transform="matrix(0.10726,0.0619268,-1.83335e-14,18.1609,1256.76,-11932.8)">
                    <rect
                      x="1335.54"
                      y="694.688"
                      width="18.525"
                      height="6.511"
                      style={{ fill: 'rgb(238, 238, 238)' }}
                    />
                  </g>
                </g>
              </g>
              <g transform="matrix(0.316667,0,0,0.316667,269.139,37.8829)">
                <g transform="matrix(0.989011,-0.571006,1.14201,0.659341,-335.171,81.4498)">
                  <rect
                    x="495.52"
                    y="1057.87"
                    width="105.078"
                    height="91"
                    style={{ fill: 'rgb(253, 243, 228)' }}
                  />
                </g>
                <g transform="matrix(0.164835,-0.0951676,1.14201,0.659341,116.224,-179.163)">
                  <rect
                    x="495.52"
                    y="1057.87"
                    width="105.078"
                    height="91"
                    style={{ fill: 'rgb(202, 174, 136)' }}
                  />
                </g>
                <g transform="matrix(0.978261,-0.564799,1.26804e-16,1.30435,-337.046,42.0327)">
                  <rect
                    x="1844.06"
                    y="1192.54"
                    width="106.232"
                    height="92"
                    style={{ fill: 'rgb(196, 173, 142)' }}
                  />
                </g>
                <g transform="matrix(0.267591,-0.154493,3.46856e-17,0.356787,992.686,475.823)">
                  <rect
                    x="1844.06"
                    y="1192.54"
                    width="106.232"
                    height="92"
                    style={{ fill: 'rgb(102, 102, 102)' }}
                  />
                </g>
                <g transform="matrix(1.28257,-0.740494,1.23317e-16,1.7101,1501.14,624.071)">
                  <g transform="matrix(1,0,0,1,-6,-6)">
                    <path
                      d="M2.25,10.5C2.25,10.5 1.5,10.5 1.5,9.75C1.5,9 2.25,6.75 6,6.75C9.75,6.75 10.5,9 10.5,9.75C10.5,10.5 9.75,10.5 9.75,10.5L2.25,10.5ZM6,6C7.234,6 8.25,4.984 8.25,3.75C8.25,2.516 7.234,1.5 6,1.5C4.766,1.5 3.75,2.516 3.75,3.75C3.75,4.984 4.766,6 6,6Z"
                      style={{ fill: 'white' }}
                    />
                  </g>
                </g>
                <g transform="matrix(0.725806,0.419045,1.75755e-17,1.01444,155.314,212.138)">
                  <rect
                    x="1663.92"
                    y="-407.511"
                    width="143.183"
                    height="118.292"
                    style={{ fill: 'rgb(240, 218, 183)' }}
                  />
                </g>
                <g transform="matrix(1.58977,-0.917857,1.15976e-16,2.2425,-1270.46,-614.379)">
                  <rect
                    x="1748.87"
                    y="1226.67"
                    width="10.895"
                    height="13.378"
                    style={{ fill: 'rgb(132, 97, 0)' }}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <linearGradient
            id="_Linear1"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-2.64571,4.04098,-4.04098,-2.64571,1167.67,799.269)"
          >
            <stop
              offset="0"
              style={{ stopColor: 'rgb(248, 248, 248)', stopOpacity: 1 }}
            />
            <stop
              offset="1"
              style={{ stopColor: 'rgb(248, 248, 248)', stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
      </svg>
    );
  },
});
