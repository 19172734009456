import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconObliqueLine from './icon-oblique-line.vue';

const IconObliqueLine = Object.assign(_IconObliqueLine, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconObliqueLine.name, _IconObliqueLine);
  }
});

export default IconObliqueLine;
