import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconLoading from './icon-loading.vue';

const IconLoading = Object.assign(_IconLoading, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconLoading.name, _IconLoading);
  }
});

export default IconLoading;
