import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconInfoCircleFill from './icon-info-circle-fill.vue';

const IconInfoCircleFill = Object.assign(_IconInfoCircleFill, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconInfoCircleFill.name, _IconInfoCircleFill);
  }
});

export default IconInfoCircleFill;
