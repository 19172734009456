import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconCaretDown from './icon-caret-down.vue';

const IconCaretDown = Object.assign(_IconCaretDown, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconCaretDown.name, _IconCaretDown);
  }
});

export default IconCaretDown;
