import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconFileImage from './icon-file-image.vue';

const IconFileImage = Object.assign(_IconFileImage, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconFileImage.name, _IconFileImage);
  }
});

export default IconFileImage;
