import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconPlayArrowFill from './icon-play-arrow-fill.vue';

const IconPlayArrowFill = Object.assign(_IconPlayArrowFill, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconPlayArrowFill.name, _IconPlayArrowFill);
  }
});

export default IconPlayArrowFill;
